<template>
  <div>
    <CRow>
      <CCol xs="12" lg="12">
        <CCard>
          <CCardHeader>
            <CCol class="row">
              <CCol col="11">
                {{ $lang.rules.crud.edit }}
              </CCol>
            </CCol>
          </CCardHeader>
          <CCardBody>
            <CAlert
                color="danger"
                closeButton
                :show.sync="dismissCountDown"
                fade
            >
              {{ err_msg }}
            </CAlert>
            <CForm @submit.prevent="onSubmit">
              <div
                  class="form-group"
                  :class="{ 'form-group--error': $v.rules.title.$error }"
              >
                <label class="form__label"
                >{{ $lang.rules.form.title }}
                  <required_span/>
                </label>
                <input
                    :maxlength="maxlength.title"
                    type="text"
                    :placeholder="$lang.rules.form.title"
                    class="form-control"
                    v-model="rules.title"
                    readonly
                />
                <small
                    class="error"
                    v-if="$v.rules.title.$error && !$v.rules.title.required"
                >{{ $lang.rules.validation.required.title }}</small
                >
              </div>
              <div
                  class="form-group"
                  :class="{ 'form-group--error': $v.rules.description.$error }"
              >
                <template>
                  <!--                  <vue-editor-->
                  <!--                    v-model="rules.description"-->
                  <!--                    :editor-toolbar="customToolbar"-->
                  <!--                  />-->
                  <ckeditor v-model="rules.description"></ckeditor>
                </template>
                <small
                    class="error"
                    v-if="
                    $v.rules.description.$error &&
                    !$v.rules.description.required
                  "
                >{{ $lang.rules.validation.required.description }}</small
                >
              </div>


              <div class="form-group" :class="{ 'form-group--error': '' }">
                <label class="form__label"
                >{{ $lang.rules.form.isForceFullyUpdated }}
                </label>
                <input
                    type="checkbox"
                    :placeholder="$lang.rules.form.isForceFullyUpdated"
                    :value="Yes"
                    @change="isforcefullyAcceptedRule"
                    v-model="rules.isforcefullyAcceptedRule"
                />
              </div>

              <div
                  class="form-group d-none"
                  :class="{ 'form-group--error': $v.rules.status.$error }"
              >
                <label class="form__label"
                >{{ $lang.rules.form.isForceFullyUpdated }}
                  <required_span/>
                </label>
                <v-select :options="statusOptions" v-model="rules.status">
                </v-select>
                <small
                    class="error"
                    v-if="$v.rules.status.$error && !$v.rules.status.required"
                >{{ $lang.rules.validation.required.status }}</small
                >
              </div>
              <CRow>
                <CCol col="6" class="text-left">
                  <CButton
                      type="submit"
                      size="sm"
                      color="primary"
                      :disabled="submitted"
                  >
                    <CIcon name="cil-check-circle"/>
                    {{ $lang.buttons.general.crud.update }}
                  </CButton>
                </CCol>
              </CRow>
            </CForm>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>
<script>
import Vue from "vue";
import VueForm from "vue-form";
import store from "/src/store/store.js";
import {rules} from "/src/store/url.js";
import {Mixin} from "/src/mixins/index.js";
import options from "/src/validations/validations.js";
import {ServerTable} from "vue-tables-2";
import VueSweetalert2 from "vue-sweetalert2";
import {required} from "vuelidate/lib/validators";
import required_span from "../../components/layouts/general/required-span";
import {Maxlength} from "../../store/maxlength";
import CKEditor from 'ckeditor4-vue';

Vue.use(VueSweetalert2);
Vue.use(ServerTable, {}, false);
Vue.use(VueForm, options);
Vue.use(CKEditor);
export default {
  name: "RulesCreate",
  mixins: [Mixin],
  components: {
    required_span
  },
  data() {
    return {
      submitted: false,
      customToolbar: [
        // ["bold", "italic", "underline"],
        // [{ list: "ordered" }, { list: "bullet" }],
        // ["image", "code-block"]
      ],
      adminId: "",
      err_msg: "",
      largeModal: false,
      submitType: "",
      alertMessage: "test",
      showAlert: false,
      dismissCountDown: 0,
      dismissCountDownS: 0,
      self: this,
      msg: "",
      items: [],
      modules: [],
      statusOptions: [],
      activePage: 1,
      module: rules,
      rules: {
        title: "",
        description: "",
        status: "",
        isforcefullyAcceptedRule:"Yes"
      },
      maxlength: {
        title: Maxlength.rules.title,
      },
    };
  },
  validations: {
    rules: {
      title: {
        required,
      },
      description: {
        required,
      },
      status: {
        required,
      },
    },
  },
  created() {
    store.commit("showLoader", false); // Loader Off
  },
  mounted() {
    let self = this;
    self.statusOptions.push(
        {value: "Active", label: "Active"},
        {value: "InActive", label: "InActive"}
    );
    self.dismissCountDown = 0;
    self.dismissCountDownS = 0;
    this.$root.$on("alert", (arg1, arg2) => {
    });

    if (localStorage.getItem("showAlert") !== "") {
      this.setAlert(true);
    }
    this.setAlert(false);
    localStorage.setItem("showAlert", "");
    const id = this.$route.params.id;
    self.editId = id;
    axios
        .get(this.viewUrlApi(this.module, id))
        .then((response) => {
          if (response.data) {
            const responseData = response.data.data;
            self.rules.description = responseData.description;
            self.rules.title = responseData.title;
            self.rules.status = responseData.status;
            self.post.postType = {
              value: responseData.status,
              label: responseData.status,
            };
            store.commit("showLoader", false); // Loader Off
          }
        })
        .catch(function (error) {
          store.commit("showLoader", false); // Loader Off
        });
    store.commit("showLoader", false); // Loader Off
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page);
        }
      },
    },
  },
  methods: {


    isForceFullyUpdated(e) {
      let self = this;
      this.v = e.target.checked;
      if (this.v) {
        self.rules.isActive = 'Yes';
      } else {
        self.rules.isActive = 'No';
      }
    },


    onSubmit() {
      let self = this;
      this.$v.rules.$touch();
      if (this.$v.rules.$invalid) {
        self.submitted = false; //Enable Button
        this.submitStatus = "ERROR";
      } else {
        let self = this;
        self.submitted = true; //Disable Button
        const postData = {
          title: self.rules.title,
          description: self.rules.description,
          isforcefullyAcceptedRule:self.rules.isforcefullyAcceptedRule,
          status: "Active", //self.rules.status.value
        };
        axios
            .post(this.updateUrlweb(this.module, self.editId), postData)
            .then((response) => {
              self.submitted = false; //Enable Button
              if (response.data.code === 200) {
                self.err_msg = response.data.message;
                self.dismissCountDown = 10;
                this.$router.push({name: "Rules"});
              } else {
                self.err_msg = response.data.message;
                self.dismissCountDown = 10;
              }
            })
            .catch(function (error) {
              let data;
              if (error.response.data.error) {
                data = error.response.data.error.toString();
              } else {
                data = error.response.data.message;
              }
              self.err_msg = data;
              self.dismissCountDown = 10;
              self.submitted = false; //Enable Button
            });
      }
    },
    setAlert(value) {
      this.showAlert = value;
    },
    rowClicked(item) {
      this.$router.push({path: `country/view/${item}`});
    },
    pageChange(val) {
      this.$router.push({query: {page: val}});
    },
  },
};
</script>
<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
